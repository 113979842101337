import { onLCP, onINP, onCLS, onTTFB, onFCP } from 'web-vitals';

import type { ReportCallback } from 'web-vitals';

type PerformanceData = {
  firstPaint: number;
  largestContentfulPaint?: number;
  firstContentfulPaint?: number;
  firstInputDelay?: number;
  ttfb?: number;
  domInteractive?: number;
  domComplete?: number;
  domContentLoaded?: number;
  onLoad?: number;
  cumulativeLayoutShift?: number;
};

const defaultTimeout = 60000;

const promiseWithTimeout = <T>(
  executor: ConstructorParameters<PromiseConstructor>[0],
  timeout: number = defaultTimeout,
  valueToResolve: T = null,
): Promise<T> => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      resolve(valueToResolve);
    }, timeout);

    const resolveAndClearTimeout = (value: T) => {
      clearTimeout(timeoutId);
      resolve(value);
    };

    const rejectAndClearTimeout = (reason: unknown) => {
      clearTimeout(timeoutId);
      reject(reason);
    };

    executor(resolveAndClearTimeout, rejectAndClearTimeout);
  });
};

const sendToPulse = (data: PerformanceData): void => {
  const object = {
    type: 'View',
    object: {
      id: 'loadtest',
      type: 'Content',
      contentId: 'loadtest',
      name: 'Load metrics on frontpage',
      custom: {
        frontVersion: 'fastenposten',
        performanceMeasurement: data,
        runtime: window?.frontendConfig?.serverRuntime || 'unknown',
      },
    },
  };

  window.pulse('track', 'trackerEvent', object, true);
};

export const initPulsePerformance = (): void => {
  async function performanceTracking() {
    if (
      !window ||
      !window.performance ||
      !window.performance.timing ||
      !window.URL
    ) {
      return;
    }

    const continuousMetricValues: { cls: number | null } = {
      cls: null,
    };

    const createContinuousReportHandler = (
      id: keyof typeof continuousMetricValues,
    ): ReportCallback => {
      return (metric) => {
        continuousMetricValues[id] = metric.value;
      };
    };

    const isChromium = Boolean(window.chrome);
    const isSafari = Boolean(window.safari);
    const isMobileSafari =
      !navigator.userAgent.match('Chrome') &&
      !navigator.userAgent.match('CriOS') &&
      !!navigator.userAgent.match('Safari');

    if (isChromium) {
      onCLS(createContinuousReportHandler('cls'), {
        reportAllChanges: true,
      });
    }

    const ttfbPromise = promiseWithTimeout<number>((resolve) => {
      onTTFB(({ value }) => {
        resolve(value);
      });
    });

    const fcpPromise = promiseWithTimeout<number>((resolve) => {
      onFCP(({ value }) => {
        resolve(value);
      });
    });

    const lcpPromise = promiseWithTimeout<number>((resolve) => {
      if (isChromium) {
        onLCP(({ value }) => {
          resolve(value);
        });
      } else {
        resolve(null);
      }
    });

    const fidPromise = promiseWithTimeout<number>((resolve) => {
      if (isSafari || isMobileSafari) {
        resolve(null);
      }

      onINP(({ value }) => {
        resolve(value);
      });
    });

    let data: PerformanceData = null;

    try {
      data = {
        largestContentfulPaint: await lcpPromise,
        firstContentfulPaint: await fcpPromise,
        firstInputDelay: await fidPromise,
        ttfb: await ttfbPromise,
        cumulativeLayoutShift: continuousMetricValues.cls,
        domInteractive: null,
        domComplete: null,
        domContentLoaded: null,
        firstPaint: null,
        onLoad: null,
      };
    } catch (error) {
      console.error('Error in performanceTracking', error);
    }

    if (window.performance.getEntriesByName) {
      const [firstPaint] = window.performance.getEntriesByName(
        'first-paint',
        'paint',
      );

      if (firstPaint) {
        data.firstPaint = firstPaint.startTime;
      }
    }

    if (window.performance.getEntriesByType) {
      const [navigation] = window.performance.getEntriesByType('navigation');

      if (navigation) {
        data.domInteractive = navigation.domInteractive;
        data.domComplete = navigation.domComplete;
        data.domContentLoaded =
          navigation.domContentLoadedEventStart - navigation.startTime;
        data.onLoad = navigation.loadEventEnd - navigation.startTime;
      }
    }

    requestAnimationFrame(() => sendToPulse(data));
  }

  window.addEventListener('load', performanceTracking);
};
